import './App.css';
import * as React from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import HorizontalStepperBare from './components/HorizontalNonLinearStepper';
import HorizontalStepperPE from './components/HorizontalNonLinearStepper_PE';

import { createTheme, ThemeProvider, styled } from '@mui/material/styles'


const theme = createTheme({
  typography: {
    fontFamily: [
      "HelveticaNeue",
      "Roboto",
      "Arial",
      "sans-serif"
    ].join(",")
  },
  components: {
    fontFamily: [
      "HelveticaNeue",
      "Roboto",
      "Arial",
      "sans-serif"
    ].join(",")
  }
});

function App() {

  const handlePE = () => {
    setValues({ ...values, compare_against: 'pe' });

  };

  const handleBARE = () => {
    setValues({ ...values, compare_against: 'bare' });
  };

  const card = (
    <React.Fragment>
      <div style={{}}>
        <CardContent>
          <Typography sx={{ fontSize: 24, fontFamily: 'HelveticaNeue', fontWeight: 'bold', textTransform: 'uppercase' }} color="#65ac1e" gutterBottom>
            Learn how you can benefit from using certified soil-biodegradable ecovio<sup>&#174;</sup> for mulch films
          </Typography>
          <Typography sx={{ fontSize: 16 }} color="text.primary">
          </Typography>
          <Typography sx={{ mb: 1.5, fontSize: 17, fontFamily: 'HelveticaNeue' }} color="text.secondary">
            Simply fill in the boxes with the required data and compare the economics of using mulch films made of ecovio<sup>&#174;</sup> to those made of polyethylene (PE) or to bare soil.<br></br> Please note that only parameters are considered that are different in the respective usages.
          </Typography>
          <Typography sx={{ fontSize: 18, fontFamily: 'HelveticaNeue' }} color="#555555">
            Compare mulch films made of ecovio<sup>&#174;</sup> with:
          </Typography>
        </CardContent>
        <CardActions sx={{ align: 'center', justifyContent: 'center' }}>
          <Button sx={{ ":hover": { color: 'white', backgroundColor: '#00793A' }, margin: '3%', marginTop: '0%', borderRadius: '0', color: 'white', backgroundColor: '#65AC1E', borderColor: 'white', fontFamily: 'HelveticaNeue' }} align="center" onClick={handlePE} size="large"><strong>PE</strong></Button>
          <Button sx={{ ":hover": { color: 'white', backgroundColor: '#00793A' }, margin: '3%', marginTop: '0%', borderRadius: '0', color: 'white', backgroundColor: '#65AC1E', borderColor: 'white', fontFamily: 'HelveticaNeue' }} align="center" onClick={handleBARE} size="large"><strong>BARE SOIL</strong></Button>
        </CardActions>
      </div>
    </React.Fragment>
  );

  const [values, setValues] = React.useState({
    compare_against: 'null',
  });

  return (
    <div className="ecovio-calculator-App" align="center">
      <div><p></p></div>
      {/*<h1>ecovio calculator functional // 07.06.2022</h1>
      <TabPanel />*/}
      <div align="center" class="print">
        <style>{`@page { size: landscape; }`}</style>
        {/* @media print{@page {size: landscape}} */}
        {
          (values.compare_against === 'pe') ? (
            <React.Fragment>
              <ThemeProvider theme={theme}>
                <Box sx={{ backgroundColor: 'rgba(255,255,255,0.75)', width: '100%', paddingTop: '3rem', paddingBottom: '3rem' }}>
                  <HorizontalStepperPE></HorizontalStepperPE>
                </Box>
              </ThemeProvider>
            </React.Fragment>
          ) : (values.compare_against === 'bare') ? (
            <React.Fragment >
              <ThemeProvider theme={theme}>
                <Box sx={{ backgroundColor: 'rgba(255,255,255,0.75)', width: '100%', paddingTop: '3rem', paddingBottom: '3rem' }}>
                  <HorizontalStepperBare></HorizontalStepperBare>
                </Box>
              </ThemeProvider>
            </React.Fragment>) :
            (<React.Fragment>
              <ThemeProvider theme={theme}>
                <Box align="center" sx={{ minWidth: 275 }}>
                  <Card variant="outlined" sx={{ backgroundColor: 'rgba(255,255,255,0.89)' }}>{card}</Card>
                </Box>
              </ThemeProvider>
            </React.Fragment>)
        }</div>

    </div>
  );
}
export default App;