import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import './fonts/HelveticaNeue/HelveticaNeueLTCom-Roman.ttf'
import './fonts/HelveticaNeue/HelveticaNeueLTCom-Bd.ttf'

ReactDOM.render(
  <React.StrictMode>
    <React.Fragment>

    <CssBaseline />
      <Container maxWidth="md">
      <App />
      </Container>
    </React.Fragment>
    
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
