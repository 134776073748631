import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { common } from '@mui/material/colors';
import Link from '@mui/material/Link';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
//import Tooltip from '@mui/material/Tooltip';
import { createTheme, ThemeProvider } from '@mui/material/styles'



const inputFieldSize_mobile = '17ch';
const inputFieldSize_pc = '25ch';

const theading_fSize_top = '85%';
const theading_fSize = '75%';
/*
const styles = theme => ({
  root: {
    width: "90%"
  },
  button: {
    marginRight: theme.spacing.unit
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit
  },
  icon: {
    color: theme.palette.secondary.main,
    "&$activeIcon": {
      color: theme.palette.secondary.main
    },
    "&$completedIcon": {
      color: theme.palette.secondary.main
    }
  },
  activeIcon: {},
  completedIcon: {}
});
*/
const theme = createTheme({
  typography: {
    fontFamily: [
      "HelveticaNeue",
      "Roboto",
      "Arial",
      "sans-serif"
    ].join(",")
  },
  components: {
    fontFamily: [
      "HelveticaNeue",
      "Roboto",
      "Arial",
      "sans-serif"
    ].join(",")
  }
});

const CustomTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.white, /*'rgba: 255 25 25 255', /*theme.palette.success.main,*/
  padding: '0px',
  borderBottom: 'none',
}));

const CustomOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: 0,
  borderColor: theme.palette.common.red,
  border: 4,
  outlineColor: theme.palette.common.red,
}));

/*
const CustomFilledInput = styled(FilledInput)(({ theme }) => ({
  color: theme.palette.common.black,
  backgroundColor: common.white,
  borderRadius: 0,
  ":hover": { backgroundColor: common.white },
  ":focus": { backgroundColor: common.white },
  ":target": { backgroundColor: common.white },
  ":not": { backgroundColor: common.white },
  ":default": { backgroundColor: common.white },
  ":empty": { backgroundColor: common.white },
  ":any": { backgroundColor: common.white },
  ":checked": { backgroundColor: common.white },
  ":root": { backgroundColor: common.white },
  ":visited": { backgroundColor: common.white },
  ":active": { backgroundColor: common.white },
  ":enabled": { backgroundColor: common.white },
  ":disabled": { backgroundColor: common.white },
  ":lang": { backgroundColor: common.white },
  ":link": { backgroundColor: common.white },
  ":valid": { backgroundColor: common.white },
  ":invalid": { backgroundColor: common.white },
}));
*/

const CustomStepButton = styled(StepButton)(({ theme }) => ({
  color: theme.palette.common.red,
  stepIcon: {
    color: theme.palette.common.red,
  },
  Icon: {
    color: common.red
  }


}));

/*
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));
*/

const steps_pc = ['Features of mulch film', 'Collection and recycling', 'Yield', 'Result'];
const steps_mobile = ["", "", "", ""];
const TABLE_BG_COLOR = '#65AC1E';


export default function HorizontalNonLinearStepper() {

  const [steps, setSteps] = React.useState(steps_pc);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const [values, setValues] = React.useState({
    pe_mulch_thickness: "", //20,
    ecovio_mulch_thickness: "", //15,
    pe_mulch_width: "", //1,
    ecovio_mulch_width: "", //1,
    both_row_distance: "", //1.4,
    pe_mulch_purchase_price_by_weight: "", //2.5,
    ecovio_mulch_purchase_price_by_weight: "", //5,

    pe_collection_hours_mulch: "", //20,
    ecovio_collection_hours_mulch: "",
    pe_collection_labor_costs: "", //12,
    ecovio_collection_labor_costs: "",
    pe_transportation_cost_mulch: "", //0.5,
    ecovio_transportation_cost_mulch: "",
    pe_recyling_fee: "", //0.27,
    ecovio_recycling_fee: "",

    PE_crop_price: "", //50,
    PE_yield_obtained: "", //100,
  });

  const [calculatedValues, setCalculatedValues] = React.useState({

    ecovio_mulch_thickness_meter: 0,
    pe_mulch_thickness_meter: 0,
    ecovio_mulch_weight_by_area: 0,
    pe_mulch_weight_by_area: 0,
    ecovio_mulch_total_price_by_area: 0,
    pe_mulch_total_price_by_area: 0,

    pe_labor_cost_by_area: 240,
    ecovio_collection_and_recycling_total_costs: 0.0,
    pe_collection_and_recyling_total_costs: 0,

    ecovio_crop_price: 0,
    ecovio_yield_obtained_by_area: 0,

    result_ecovio_gross_income: 0,
    result_PE_gross_income: 0,
    result_ecovio_total_costs: 0,
    result_PE_total_costs: 0,
    mulch_ecovio_price_by_area: 0,
    mulch_PE_price_by_area: 0,
  }
  );

  const [activement, setActivement] = React.useState({});


  // only for testing next lines!
  const [search, setSearch] = React.useState("");

  const searchInput = React.useRef(null);
  React.useEffect(() => {
    //searchInput.current.focus();
  }, [search]);
  // end of testing






  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const handleResize = () => {
    const isMobile = window.matchMedia("(max-width: 625px)");
    if (isMobile.matches) {
      //console.log("is mobile stepper!");
      //setSteps();
      setSteps(steps_mobile);
      handleStep(0);
      setActiveStep(0);
      //console.log("länge: " + steps.length)
    }
    else if (!isMobile.matches) {
      //console.log("is pc stepper!");
      //setSteps();
      setSteps(steps_pc);
      handleStep(0);
      setActiveStep(0);
      //console.log("länge: " + steps.length)
    }
  }
  React.useEffect(() => {
    window.addEventListener("resize", handleResize, false);
    const isMobile = window.matchMedia("(max-width: 625px)");
    if (isMobile.matches) {
      //console.log("is mobile stepper!");
      //setSteps();
      setSteps(steps_mobile);
      handleStep(0);
      setActiveStep(0);
      //console.log("länge: " + steps.length)
    }
    else if (!isMobile.matches) {
      //console.log("is pc stepper!");
      //setSteps();
      setSteps(steps_pc);
      handleStep(0);
      setActiveStep(0);
      //console.log("länge: " + steps.length)
    }
  }, []);




  React.useEffect(() => {
    //console.log("UseEffect called!")
    handleCalculations();
    //alert("" + activement);
    try {
      //document.getElementById(activement).focus();
    } catch { }


    // eslint-disable-next-line
  }, [values]);


  function handleCalculations() {
    let calculated_ecovio_thickness_meter = values.ecovio_mulch_thickness / 1000000;
    let calculated_pe_thickness_meter = values.pe_mulch_thickness / 1000000;

    let calculated_ecovio_weight_of_mulch_by_area = (0 + (100 / values.both_row_distance) * values.ecovio_mulch_width * 100 * calculated_ecovio_thickness_meter * 1370);
    if (isNaN(calculated_ecovio_weight_of_mulch_by_area)) {
      calculated_ecovio_weight_of_mulch_by_area = 0.0
    }
    let calculated_pe_weight_of_mulch_by_area = (0 + (100 / values.both_row_distance) * values.pe_mulch_width * 100 * calculated_pe_thickness_meter * 925);
    if (isNaN(calculated_pe_weight_of_mulch_by_area)) {
      calculated_pe_weight_of_mulch_by_area = 0.0
    }
    //alert(calculated_ecovio_weight_of_mulch_by_area);

    let calculated_ecovio_mulch_price_by_area = parseFloat(0 + (values.ecovio_mulch_purchase_price_by_weight * calculated_ecovio_weight_of_mulch_by_area));
    let calculated_pe_mulch_price_by_area = (0 + values.pe_mulch_purchase_price_by_weight * calculated_pe_weight_of_mulch_by_area);

    let calculated_pe_labor_costs_by_area = (values.pe_collection_hours_mulch * values.pe_collection_labor_costs);
    let temp_calc_fees = parseFloat(values.pe_transportation_cost_mulch) + parseFloat(values.pe_recyling_fee);
    let calculated_pe_total_collectiond_and_recycling_costs = parseFloat(temp_calc_fees * parseFloat(calculated_pe_weight_of_mulch_by_area)) + parseFloat(calculated_pe_labor_costs_by_area);
    //console.log("calulating: " + values.pe_transportation_cost_mulch + "+" + values.pe_recyling_fee + "=" + (parseFloat(values.pe_transportation_cost_mulch) + parseFloat(values.pe_recyling_fee)) + " * " + parseFloat(calculated_pe_weight_of_mulch_by_area) + " = " + (parseFloat(values.pe_transportation_cost_mulch + parseFloat(values.pe_recyling_fee)) * parseFloat(calculated_pe_weight_of_mulch_by_area)))
    let calculated_ecovio_total_collection_and_recylcing_costs = 0;

    let calculated_ecovio_crop_price = values.PE_crop_price;
    let calculated_ecovio_yield_obtained = (values.PE_yield_obtained * 1);

    let calculated_ecovio_gross_income = (calculated_ecovio_crop_price * calculated_ecovio_yield_obtained);
    let calculated_PE_gross_income = (values.PE_crop_price * values.PE_yield_obtained);

    let calculated_pe_total_costs_of_mulch_film = (parseFloat(calculated_pe_mulch_price_by_area) + parseFloat(calculated_pe_total_collectiond_and_recycling_costs));
    let calculated_ecovio_total_costs_of_mulch_film = parseFloat((parseFloat(calculated_ecovio_mulch_price_by_area) + parseFloat(calculated_ecovio_total_collection_and_recylcing_costs)));

    let calculated_PE_final_income = parseFloat(calculated_ecovio_gross_income - calculated_pe_total_costs_of_mulch_film);
    let calculated_ecovio_final_income = (parseFloat(calculated_ecovio_gross_income) - parseFloat(calculated_ecovio_total_costs_of_mulch_film));

    setCalculatedValues({
      ...calculatedValues,
      ecovio_mulch_thickness_meter: calculated_ecovio_thickness_meter.toFixed(2),
      pe_mulch_thickness_meter: calculated_pe_thickness_meter.toFixed(2),

      ecovio_mulch_weight_by_area: calculated_ecovio_weight_of_mulch_by_area.toFixed(2),
      pe_mulch_weight_by_area: calculated_pe_weight_of_mulch_by_area.toFixed(2),

      ecovio_mulch_total_price_by_area: isNaN(calculated_ecovio_mulch_price_by_area) ? 0.00 : calculated_ecovio_mulch_price_by_area.toFixed(2),
      pe_mulch_total_price_by_area: (isNaN(calculated_pe_mulch_price_by_area) ? 0.00 : calculated_pe_mulch_price_by_area.toFixed(2)),

      pe_labor_cost_by_area: calculated_pe_labor_costs_by_area.toFixed(2),
      pe_collection_and_recyling_total_costs: isNaN(calculated_pe_total_collectiond_and_recycling_costs) ? 0 : calculated_pe_total_collectiond_and_recycling_costs.toFixed(2),

      result_ecovio_gross_income: calculated_ecovio_gross_income.toFixed(2),
      result_PE_gross_income: calculated_PE_gross_income.toFixed(2),
      result_ecovio_total_costs: isNaN(calculated_ecovio_total_costs_of_mulch_film) ? 0.001.toFixed(2) : calculated_ecovio_total_costs_of_mulch_film.toFixed(2),
      result_PE_total_costs: isNaN(calculated_pe_total_costs_of_mulch_film) ? 0.001.toFixed(2) : calculated_pe_total_costs_of_mulch_film.toFixed(2),
      result_ecovio_final_income: isNaN(calculated_ecovio_final_income) ? 0.001.toFixed(2) : calculated_ecovio_final_income.toFixed(2),
      result_pe_final_income: isNaN(calculated_PE_final_income) ? 0.001.toFixed(2) : calculated_PE_final_income.toFixed(2),
    });

  };

  const tableMarginTop = '9px';
  const tableMarginBottom = '9px';

  const handleChange = (prop) => (event) => {
    console.log("clicked on " + event.target.id);
    console.log("<" + event.target.value + ">");
    if (false /*isNaN(event.target.value) || event.target.value === 0 || event.target.value == ''*/) {
      setValues({ ...values, [prop]: 0 });

      console.log("is nan");
    }
    else {
      setValues({ ...values, [prop]: event.target.value })
    }
    //setValues({ ...values, [prop]: event.target.value })
    setActivement(event.target.id);
    // testing
    //setSearch("search");
  };


  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
        // find the first step that has been completed
        steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
    window.scroll(0, 0);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    window.scroll(0, 0);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
    window.scroll(0, 0);
  };

  /*
  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };
  */

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const handleRestart = () => {
    window.location.reload(false);
  }

  const handlePrint = () => {
    //window.location.reload(false);
    //alert("Printing!");
    window.print();
  }

  function InfoBox(props) {
    return (
      <Tooltip sx={{ position: "absolute", color: "white", fontSize: "1rem", marginTop: "0.01rem", marginLeft: "-1rem" }} title={props.title}>
        <IconButton >
          <InfoOutlined />
        </IconButton>
      </Tooltip>
    );
  }


  function CustomResultCell(props) {
    return (
      <React.Fragment className="">
        <Typography sx={{ paddingBottom: '0.5rem', fontSize: '0.8rem' }}>
          {props.heading}
        </Typography>
        <Typography sx={{ width: 'auto', align: 'center', fontWeight: 'bold', fontSize: '1rem', backgroundColor: 'lightgray', color: 'black', paddingTop: '0.85rem', paddingBottom: '0.85rem' }}>
          {props.value} {props.unit}
        </Typography>
      </React.Fragment>
    )
  };






  /*
    const YieldAndIncomeTable = () => {
      return (
        <React.Fragment>
  
          <TableContainer align="center" style={{ background: TABLE_BG_COLOR }}>
            <Table sx={{ minWidth: 575, '@media (max-width: 625px)' : { minWidth: 260}, }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ borderBottom: "none", color: 'white' }} colSpan={2} align="center"><h1>Yield and income</h1>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <CustomTableCell align="center"><h1 style={{ marginTop: '1px', marginBottom: '1px' }}>{"PE mulch film"}</h1></CustomTableCell>
                  <CustomTableCell align="center"><h1 style={{ marginTop: '1px', marginBottom: '1px' }}>ecovio<sup>&#174;</sup></h1></CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  key={'row.name3'}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <CustomTableCell style={{ borderBottom: "none" }} align="center">
                    <FormControl sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)' : { width: inputFieldSize_mobile}, marginBottom: tableMarginBottom, marginTop: tableMarginTop }} variant="outlined">
                      <FormHelperText sx={{ color: common.white, paddingBottom: '0.35rem' }} id="outlined-weight-helper-text">Crop price</FormHelperText>
                      <CustomOutlinedInput
                        type="number"
                        id="outlined-adornment-weight"
                        value={values.PE_crop_price}
                        onChange={handleChange('PE_crop_price')}
                        endAdornment={<InputAdornment position="end">€/mt</InputAdornment>}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          'aria-label': 'PE_crop_price',
                          type: 'number',
                          min: 0,
                          step: 0.1,
                        }}
                      />
                    </FormControl>
                  </CustomTableCell>
                  <CustomTableCell style={{ borderBottom: "none" }} align="center">
                    <FormControl sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)' : { width: inputFieldSize_mobile}, marginBottom: tableMarginBottom, marginTop: tableMarginTop }} variant="outlined">
                      <FormHelperText sx={{ color: common.white, paddingBottom: '0.35rem' }} id="outlined-weight-helper-text">Crop price</FormHelperText>
                      <CustomOutlinedInput
                        disabled
                        type="number"
                        id="outlined-adornment-weight"
                        value={values.PE_crop_price}
                        endAdornment={<InputAdornment position="end">€/mt</InputAdornment>}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          'aria-label': 'ecovio_crop_price',
                          min: 0,
                          step: 0.1,
                        }}
                      />
                    </FormControl>
                  </CustomTableCell>
                </TableRow>
                <TableRow
                  key={'row.name33'}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <CustomTableCell style={{ borderBottom: "none" }} align="center">
                    <FormControl sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)' : { width: inputFieldSize_mobile}, marginBottom: tableMarginBottom, marginTop: tableMarginTop }} variant="outlined">
                      <FormHelperText sx={{ color: common.white, paddingBottom: '0.35rem' }} id="outlined-weight-helper-text">Obtained yield</FormHelperText>
                      <CustomOutlinedInput
                        type="number"
                        id="outlined-adornment-weight"
                        value={values.PE_yield_obtained}
                        onChange={handleChange('PE_yield_obtained')}
                        endAdornment={<InputAdornment position="end">mt/ha</InputAdornment>}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          'aria-label': 'PE_yield_obtained',
                          min: 0,
                          step: 0.1,
                        }}
                      />
                    </FormControl>
                  </CustomTableCell>
                  <CustomTableCell style={{ borderBottom: "none" }} align="center">
                    <FormControl sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)' : { width: inputFieldSize_mobile}, marginBottom: tableMarginBottom, marginTop: tableMarginTop }} variant="outlined">
                      <FormHelperText sx={{ color: common.white, paddingBottom: '0.35rem' }} id="outlined-weight-helper-text">Obtained yield</FormHelperText>
                      <CustomOutlinedInput
                        disabled
                        type="number"
                        id="outlined-adornment-weight"
                        value={values.PE_yield_obtained}
                        endAdornment={<InputAdornment position="end">mt/ha</InputAdornment>}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          'aria-label': 'ecovio_yield_obtained_by_area',
                          min: 0,
                          step: 0.1,
                        }}
                      />
                    </FormControl>
                  </CustomTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </React.Fragment>
      );
    }
  */

  return (
    <Box sx={{ width: '97%' }}>
      <div class="noPrint">
        <style>
          {`@media print {.noPrint{display: none;}}`}
        </style>
        <Stepper nonLinear alternativeLabel activeStep={activeStep} sx={{ color: 'red !important' }}>
          {steps.map((label, index) => (
            <Step className="mui-active-icon" key={label + "xyx" + Math.random() + "_" + Math.random()} completed={completed[index]}
              sx={{
                '& .MuiStepLabel-root .Mui-completed': {
                  color: '#65ac1e', // circle color (COMPLETED)
                },
                '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                {
                  color: 'black', // Just text label (COMPLETED)
                },
                '& .MuiStepLabel-root .Mui-active': {
                  color: '#65ac1e', // circle color (ACTIVE)
                },
                '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                {
                  color: 'common.black', // Just text label (ACTIVE)
                  fontWeight: 700,
                },
                '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                  fill: 'white', // circle's number (ACTIVE)
                },
              }}
            >
              <CustomStepButton disableRipple sx={{ color: 'sucess.main !important' }} onClick={handleStep(index)}>
                {label}
              </CustomStepButton>
            </Step>
          ))}
        </Stepper>
      </div>
      <div>
        {allStepsCompleted() ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment margin="center">
            {/*<Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
        </Button>*/}
            {/*<Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>*/}
            <Box justifyContent="center" sx={{ display: 'flex', flexDirection: 'row', pt: 3 }}>
              <div>
                {
                  (activeStep + 1 === 1) ? (
                    <React.Fragment>
                      {/*<MulchFeaturesTable></MulchFeaturesTable>*/}
                      <React.Fragment key="mulchfrag">
                        <TableContainer key="mulchtablecontainer" align="center" style={{ background: TABLE_BG_COLOR }}>
                          <Table key="mulchtable" sx={{ minWidth: 575, '@media (max-width: 625px)': { minWidth: 260 }, }} aria-label="simple table">
                            <TableHead >
                              <TableRow >
                                <TableCell sx={{ paddingBottom: '10px', '@media (max-width: 625px)': { fontSize: theading_fSize_top } }} style={{ borderBottom: "none", color: 'white' }} colSpan={2} align="center"><h1>Enter the features of the mulch film</h1>
                                </TableCell>
                              </TableRow>
                              <TableRow >
                                <CustomTableCell sx={{ '@media (max-width: 625px)': { fontSize: theading_fSize } }} style={{ borderBottom: "none" }} align="center"><h1 style={{ marginTop: '1px', marginBottom: '1px' }}>{"PE mulch film"}</h1></CustomTableCell>
                                <CustomTableCell sx={{ '@media (max-width: 625px)': { fontSize: theading_fSize } }} style={{ borderBottom: "none" }} align="center"><h1 style={{ marginTop: '3px', marginBottom: '3px' }}>ecovio<sup style={{lineHeight: '0px'}}>&#174;</sup></h1></CustomTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody key="mulchtablebody">
                              <TableRow
                                key={'row.name1'}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <CustomTableCell key="pethickcell" style={{ borderBottom: "none" }} align="center">
                                  <FormControl id="2893zhsdibf" key="mulchtihckpereal" sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)': { width: inputFieldSize_mobile }, marginBottom: tableMarginBottom, marginTop: tableMarginTop }} variant="outlined">
                                    <FormHelperText key="justtomakesure" sx={{ color: common.white, paddingBottom: '0.35rem' }} id="outlined-weight-helper-text">Thickness</FormHelperText>
                                    <CustomOutlinedInput
                                      key="tst112"
                                      type="number"
                                      id="outlined-adornment-weight1"
                                      //placeholder=''
                                      value={values.pe_mulch_thickness}
                                      onChange={handleChange('pe_mulch_thickness')}
                                      endAdornment={<InputAdornment position="end">&#181;m</InputAdornment>}
                                      aria-describedby="outlined-weight-helper-text"
                                      inputProps={{
                                        'aria-label': 'pe_mulch_thickness',
                                        min: 0,
                                        step: 0.1,
                                      }}
                                    />
                                  </FormControl>
                                </CustomTableCell>
                                <CustomTableCell style={{ borderBottom: "none" }} align="center">
                                  <FormControl id="sadsadsf" key="mulchticksecoreal" sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)': { width: inputFieldSize_mobile }, marginBottom: tableMarginBottom, marginTop: tableMarginTop }} variant="outlined">
                                    <FormHelperText sx={{ color: common.white, paddingBottom: '0.35rem' }} id="outlined-weight-helper-text">Thickness</FormHelperText>
                                    <CustomOutlinedInput
                                      key="test223"
                                      type="number"
                                      id="outlined-adornment-weight2"
                                      //placeholder=''
                                      value={values.ecovio_mulch_thickness}
                                      onChange={handleChange('ecovio_mulch_thickness')}
                                      endAdornment={<InputAdornment position="end">&#181;m</InputAdornment>}
                                      aria-describedby="outlined-weight-helper-text"
                                      inputProps={{
                                        'aria-label': 'ecovio_mulch_thickness',
                                        min: 0,
                                        step: 0.1,
                                      }}
                                    />
                                  </FormControl>
                                </CustomTableCell>
                              </TableRow>
                              <TableRow>
                                <CustomTableCell style={{ borderBottom: "none" }} align="center">
                                  <FormControl sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)': { width: inputFieldSize_mobile }, marginBottom: tableMarginBottom, marginTop: tableMarginTop }} variant="outlined">
                                    <FormHelperText sx={{ color: common.white, paddingBottom: '0.35rem' }} id="outlined-weight-helper-text">Width</FormHelperText>
                                    <CustomOutlinedInput
                                      type="number"
                                      id="outlined-adornment-weight"
                                      //placeholder=''
                                      value={values.pe_mulch_width}
                                      onChange={handleChange('pe_mulch_width')}
                                      endAdornment={<InputAdornment position="end">m</InputAdornment>}
                                      aria-describedby="outlined-weight-helper-text"
                                      inputProps={{
                                        'aria-label': 'pe_mulch_width',
                                        min: 0,
                                        step: 0.1,
                                      }}
                                    />
                                  </FormControl>
                                </CustomTableCell>
                                <CustomTableCell style={{ borderBottom: "none" }} align="center">
                                  <FormControl sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)': { width: inputFieldSize_mobile }, marginBottom: tableMarginBottom, marginTop: tableMarginTop }} variant="outlined">
                                    <FormHelperText sx={{ color: common.white, paddingBottom: '0.35rem' }} id="outlined-weight-helper-text">Width</FormHelperText>
                                    <CustomOutlinedInput
                                      type="number"
                                      id="outlined-adornment-weight"
                                      //placeholder=''
                                      value={values.ecovio_mulch_width}
                                      onChange={handleChange('ecovio_mulch_width')}
                                      endAdornment={<InputAdornment position="end">m</InputAdornment>}
                                      aria-describedby="outlined-weight-helper-text"
                                      inputProps={{
                                        'aria-label': 'ecovio_mulch_width',
                                        min: 0,
                                        step: 0.1,
                                      }}
                                    />
                                  </FormControl>
                                </CustomTableCell>
                              </TableRow>
                              <TableRow>
                                <CustomTableCell style={{ borderBottom: "none" }} align="center">
                                  <FormControl sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)': { width: inputFieldSize_mobile }, marginBottom: tableMarginBottom, marginTop: tableMarginTop }} variant="outlined">
                                    <FormHelperText sx={{ color: common.white, paddingBottom: '0.35rem' }} id="outlined-weight-helper-text">Row distance of the crop</FormHelperText>
                                    <CustomOutlinedInput
                                      type="number"
                                      id="outlined-adornment-weight"
                                      // placeholder=''
                                      value={values.both_row_distance}
                                      onChange={handleChange('both_row_distance')}
                                      endAdornment={<InputAdornment position="end">m</InputAdornment>}
                                      aria-describedby="outlined-weight-helper-text"
                                      inputProps={{
                                        'aria-label': 'both_row_distance',
                                        min: 0,
                                        step: 0.1,
                                      }}
                                    />
                                  </FormControl>
                                </CustomTableCell>
                                <CustomTableCell style={{ borderBottom: "none" }} align="center">
                                  <FormControl sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)': { width: inputFieldSize_mobile }, marginBottom: tableMarginBottom, marginTop: tableMarginTop }} variant="outlined">
                                    <FormHelperText sx={{ color: common.white, paddingBottom: '0.35rem' }} id="outlined-weight-helper-text">Row distance of the crop</FormHelperText>
                                    <CustomOutlinedInput
                                      type="number"
                                      id="outlined-adornment-weight"
                                      // placeholder=''
                                      value={values.both_row_distance}
                                      onChange={handleChange('both_row_distance')}
                                      endAdornment={<InputAdornment position="end">m</InputAdornment>}
                                      aria-describedby="outlined-weight-helper-text"
                                      inputProps={{
                                        'aria-label': 'both_row_distance',
                                        min: 0,
                                        step: 0.1,
                                      }}
                                    />
                                  </FormControl>
                                </CustomTableCell>
                              </TableRow>
                              <TableRow>
                                <CustomTableCell align="center">
                                  <FormControl sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)': { width: inputFieldSize_mobile }, marginBottom: tableMarginBottom, marginTop: tableMarginTop }} variant="outlined">
                                    <FormHelperText sx={{ color: common.white, paddingBottom: '0.35rem' }} id="outlined-weight-helper-text">Purchase price of mulch film</FormHelperText>
                                    <CustomOutlinedInput
                                      type="number"
                                      id="outlined-adornment-weight"
                                      // placeholder=''
                                      value={values.pe_mulch_purchase_price_by_weight}
                                      onChange={handleChange('pe_mulch_purchase_price_by_weight')}
                                      endAdornment={<InputAdornment position="end">€/kg</InputAdornment>}
                                      aria-describedby="outlined-weight-helper-text"
                                      inputProps={{
                                        'aria-label': 'pe_mulch_purchase_price_by_weight',
                                        min: 0,
                                        step: 0.1,
                                      }}
                                    />
                                  </FormControl>
                                </CustomTableCell>
                                <CustomTableCell align="center">
                                  <FormControl sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)': { width: inputFieldSize_mobile }, marginBottom: tableMarginBottom, marginTop: tableMarginTop }} variant="outlined">
                                    <FormHelperText sx={{ color: common.white, paddingBottom: '0.35rem' }} id="outlined-weight-helper-text">Purchase price of mulch film</FormHelperText>
                                    <CustomOutlinedInput
                                      type="number"
                                      id="outlined-adornment-weight"
                                      // placeholder=''
                                      value={values.ecovio_mulch_purchase_price_by_weight}
                                      onChange={handleChange('ecovio_mulch_purchase_price_by_weight')}
                                      endAdornment={<InputAdornment position="end">€/kg</InputAdornment>}
                                      aria-describedby="outlined-weight-helper-text"
                                      inputProps={{
                                        'aria-label': 'ecovio_mulch_purchase_price_by_weight',
                                        min: 0,
                                        step: 0.1,
                                      }}
                                    />
                                  </FormControl>
                                </CustomTableCell>
                              </TableRow>
                              <TableRow >
                                <CustomTableCell align="center" sx={{ borderBottom: "none" }}>
                                  <FormControl sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)': { width: inputFieldSize_mobile } }} variant="outlined">
                                    <CustomResultCell value={calculatedValues.pe_mulch_total_price_by_area.toString().replace('.', ',')} heading="Purchase price of mulch film" unit="€/ha"></CustomResultCell>
                                  </FormControl>
                                </CustomTableCell>
                                <CustomTableCell align="center" sx={{ borderBottom: "none" }}>
                                  <FormControl sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)': { width: inputFieldSize_mobile } }} variant="outlined">
                                    <CustomResultCell value={calculatedValues.ecovio_mulch_total_price_by_area.toString().replace('.', ',')} heading="Purchase price of mulch film" unit="€/ha"></CustomResultCell>
                                  </FormControl>
                                </CustomTableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </React.Fragment>
                    </React.Fragment>
                  ) : (activeStep + 1 === 2) ? (
                    <React.Fragment>
                      {/*<CollectionAndRecylingTable></CollectionAndRecylingTable>*/}
                      <React.Fragment>

                        <TableContainer align="center" style={{ background: TABLE_BG_COLOR }}>
                          <Table sx={{ minWidth: 575, '@media (max-width: 625px)': { minWidth: 260 }, }} aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell sx={{ paddingBottom: '10px' }} style={{ borderBottom: "none", color: 'white' }} colSpan={2} align="center"><h1>Enter costs for collection and recycling</h1>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <CustomTableCell align="center"><h1 style={{ marginTop: '1px', marginBottom: '1px' }}>{"PE mulch film"}</h1></CustomTableCell>
                                <CustomTableCell align="center"><h1 style={{ marginTop: '1px', marginBottom: '1px' }}>ecovio<sup style={{lineHeight: '0px'}}>&#174;</sup></h1></CustomTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow
                                key={'row.name2'}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <CustomTableCell style={{ borderBottom: "none" }} align="center">
                                  <FormControl sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)': { width: inputFieldSize_mobile }, marginBottom: tableMarginBottom, marginTop: tableMarginTop }} variant="outlined">
                                    <FormHelperText sx={{ color: common.white, paddingBottom: '0.35rem' }} id="outlined-weight-helper-text">Hours to collect PE film</FormHelperText>
                                    <CustomOutlinedInput
                                      type="number"
                                      id="outlined-adornment-weight"
                                      // placeholder=''
                                      value={values.pe_collection_hours_mulch}
                                      onChange={handleChange('pe_collection_hours_mulch')}
                                      endAdornment={<InputAdornment position="end">h</InputAdornment>}
                                      aria-describedby="outlined-weight-helper-text"
                                      inputProps={{
                                        'aria-label': 'pe_collection_hours_mulch',
                                        min: 0,
                                        step: 0.1,
                                      }}
                                    />
                                  </FormControl>
                                </CustomTableCell>
                                <CustomTableCell style={{ borderBottom: "none" }} align="center">
                                  <FormControl sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)': { width: inputFieldSize_mobile }, marginBottom: tableMarginBottom, marginTop: tableMarginTop }} variant="outlined">
                                    <FormHelperText sx={{ color: common.white, paddingBottom: '0.35rem' }} id="outlined-weight-helper-text">Hours to collect film</FormHelperText>
                                    <CustomOutlinedInput
                                      disabled
                                      type="number"
                                      id="outlined-adornment-weight"
                                      value={"0.00"}
                                      endAdornment={<InputAdornment position="end">h</InputAdornment>}
                                      aria-describedby="outlined-weight-helper-text"
                                      inputProps={{
                                        'aria-label': 'ecovio_mulch_thickness',
                                        min: 0,
                                        step: 0.1,
                                      }}
                                    />
                                  </FormControl>
                                </CustomTableCell>
                              </TableRow>
                              <TableRow>
                                <CustomTableCell style={{ borderBottom: "none" }} align="center">
                                  <FormControl sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)': { width: inputFieldSize_mobile }, marginBottom: tableMarginBottom, marginTop: tableMarginTop }} variant="outlined">
                                    <FormHelperText sx={{ color: common.white, paddingBottom: '0.35rem' }} id="outlined-weight-helper-text">Labor cost to collect PE film</FormHelperText>
                                    <CustomOutlinedInput
                                      type="number"
                                      id="outlined-adornment-weight"
                                      // placeholder='' 
                                      value={values.pe_collection_labor_costs}
                                      onChange={handleChange('pe_collection_labor_costs')}
                                      endAdornment={<InputAdornment position="end">€/h</InputAdornment>}
                                      aria-describedby="outlined-weight-helper-text"
                                      inputProps={{
                                        'aria-label': 'pe_collection_labor_costs',
                                        min: 0,
                                        step: 0.1,
                                      }}
                                    />
                                  </FormControl>
                                </CustomTableCell>
                                <CustomTableCell style={{ borderBottom: "none" }} align="center">
                                  <FormControl sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)': { width: inputFieldSize_mobile }, marginBottom: tableMarginBottom, marginTop: tableMarginTop }} variant="outlined">
                                    <FormHelperText sx={{ color: common.white, paddingBottom: '0.35rem' }} id="outlined-weight-helper-text">Labor cost to collect film</FormHelperText>
                                    <CustomOutlinedInput
                                      disabled
                                      type="number"
                                      id="outlined-adornment-weight"
                                      value={"0.00"}
                                      endAdornment={<InputAdornment position="end">€/h</InputAdornment>}
                                      aria-describedby="outlined-weight-helper-text"
                                      inputProps={{
                                        'aria-label': 'ecovio_mulch_width',
                                        min: 0,
                                        step: 0.1,
                                      }}
                                    />
                                  </FormControl>
                                </CustomTableCell>
                              </TableRow>
                              <TableRow>
                                <CustomTableCell style={{ borderBottom: "none" }} align="center">
                                  <FormControl sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)': { width: inputFieldSize_mobile }, marginBottom: tableMarginBottom, marginTop: tableMarginTop }} variant="outlined">
                                    <FormHelperText sx={{ color: common.white, paddingBottom: '0.35rem' }} id="outlined-weight-helper-text">Transportation costs of collected mulch film</FormHelperText>
                                    <CustomOutlinedInput
                                      type="number"
                                      id="outlined-adornment-weight"
                                      //placeholder=''
                                      value={values.pe_transportation_cost_mulch}
                                      onChange={handleChange('pe_transportation_cost_mulch')}
                                      endAdornment={<InputAdornment position="end">€/kg</InputAdornment>}
                                      aria-describedby="outlined-weight-helper-text"
                                      inputProps={{
                                        'aria-label': 'pe_transportation_cost_mulch',
                                        min: 0,
                                        step: 0.1,
                                      }}
                                    />
                                  </FormControl>
                                </CustomTableCell>
                                <CustomTableCell style={{ borderBottom: "none" }} align="center">
                                  <FormControl sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)': { width: inputFieldSize_mobile }, marginBottom: tableMarginBottom, marginTop: tableMarginTop }} variant="outlined">
                                    <FormHelperText sx={{ color: common.white, paddingBottom: '0.35rem' }} id="outlined-weight-helper-text">Transportation costs of collected mulch film</FormHelperText>
                                    <CustomOutlinedInput
                                      disabled
                                      type="number"
                                      id="outlined-adornment-weight"
                                      value={"0.00"}
                                      endAdornment={<InputAdornment position="end">€/kg</InputAdornment>}
                                      aria-describedby="outlined-weight-helper-text"
                                      inputProps={{
                                        'aria-label': 'both_row_distance',
                                        min: 0,
                                        step: 0.1,
                                      }}
                                    />
                                  </FormControl>
                                </CustomTableCell>
                              </TableRow>
                              <TableRow>
                                <CustomTableCell align="center">
                                  <FormControl sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)': { width: inputFieldSize_mobile }, marginBottom: tableMarginBottom, marginTop: tableMarginTop }} variant="outlined">
                                    <FormHelperText sx={{ color: common.white, paddingBottom: '0.35rem' }} id="outlined-weight-helper-text">Recycling fee (+ other fees)</FormHelperText>
                                    <CustomOutlinedInput
                                      name="search"
                                      ref={searchInput}
                                      type="number"
                                      id="outlined-adornment-weight"
                                      // placeholder=''
                                      value={values.pe_recyling_fee}
                                      onChange={handleChange('pe_recyling_fee')}
                                      endAdornment={<InputAdornment position="end">€/kg</InputAdornment>}
                                      aria-describedby="outlined-weight-helper-text"
                                      inputProps={{
                                        'aria-label': 'pe_recyling_fee',
                                        min: 0,
                                        step: 0.1,
                                      }}
                                    />
                                  </FormControl>
                                  <InfoBox title="This should include all fees which are due to recycle the PE mulch film. Some of them could also be paid at the purchase. "></InfoBox>
                                </CustomTableCell>
                                <CustomTableCell align="center">
                                  <FormControl sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)': { width: inputFieldSize_mobile }, marginBottom: tableMarginBottom, marginTop: tableMarginTop }} variant="outlined">
                                    <FormHelperText sx={{ color: common.white, paddingBottom: '0.35rem' }} id="outlined-weight-helper-text">Recycling fee (+ other fees)</FormHelperText>
                                    <CustomOutlinedInput
                                      disabled
                                      type="number"
                                      id="outlined-adornment-weight"
                                      value={"0.00"}
                                      endAdornment={<InputAdornment position="end">€/kg</InputAdornment>}
                                      aria-describedby="outlined-weight-helper-text"
                                      inputProps={{
                                        'aria-label': 'ecovio_mulch_purchase_price_by_weight',
                                        min: 0,
                                        step: 0.1,
                                      }}
                                    />
                                  </FormControl>
                                </CustomTableCell>
                              </TableRow>
                              <TableRow>
                                <CustomTableCell align="center" style={{ borderBottom: "none" }}>
                                  <FormControl sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)': { width: inputFieldSize_mobile } }} variant="outlined">
                                    <CustomResultCell value={calculatedValues.pe_collection_and_recyling_total_costs.toString().replace('.', ',')} heading="Total cost of collection and recycling" unit="€/ha"></CustomResultCell>
                                  </FormControl>
                                </CustomTableCell>
                                <CustomTableCell align="center" style={{ borderBottom: "none" }}>
                                  <FormControl sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)': { width: inputFieldSize_mobile } }} variant="outlined">
                                    <CustomResultCell value={0} heading="Total cost of collection and recycling" unit="€/ha"></CustomResultCell>
                                  </FormControl>
                                </CustomTableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </React.Fragment>
                    </React.Fragment>
                  ) : (activeStep + 1 === 3) ? (
                    <React.Fragment>
                      {/*<YieldAndIncomeTable></YieldAndIncomeTable>*/}
                      <React.Fragment>

                        <TableContainer align="center" style={{ background: TABLE_BG_COLOR }}>
                          <Table sx={{ minWidth: 575, '@media (max-width: 625px)': { minWidth: 260 }, }} aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell sx={{ paddingBottom: '10px' }} style={{ borderBottom: "none", color: 'white' }} colSpan={2} align="center"><h1>Yield and income</h1>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <CustomTableCell align="center"><h1 style={{ marginTop: '1px', marginBottom: '1px' }}>{"PE mulch film"}</h1></CustomTableCell>
                                <CustomTableCell align="center"><h1 style={{ marginTop: '1px', marginBottom: '1px' }}>ecovio<sup style={{lineHeight: '0px'}}>&#174;</sup></h1></CustomTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow
                                key={'row.name3'}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <CustomTableCell style={{ borderBottom: "none" }} align="center">
                                  <FormControl sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)': { width: inputFieldSize_mobile }, marginBottom: tableMarginBottom, marginTop: tableMarginTop }} variant="outlined">
                                    <FormHelperText sx={{ color: common.white, paddingBottom: '0.35rem' }} id="outlined-weight-helper-text">Crop price</FormHelperText>
                                    <CustomOutlinedInput
                                      type="number"
                                      id="outlined-adornment-weight"
                                      //placeholder='' 
                                      value={values.PE_crop_price}
                                      onChange={handleChange('PE_crop_price')}
                                      endAdornment={<InputAdornment position="end">€/mt</InputAdornment>}
                                      aria-describedby="outlined-weight-helper-text"
                                      inputProps={{
                                        'aria-label': 'PE_crop_price',
                                        min: 0,
                                        step: 0.1,
                                      }}
                                    />
                                  </FormControl>
                                </CustomTableCell>
                                <CustomTableCell style={{ borderBottom: "none" }} align="center">
                                  <FormControl sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)': { width: inputFieldSize_mobile }, marginBottom: tableMarginBottom, marginTop: tableMarginTop }} variant="outlined">
                                    <FormHelperText sx={{ color: common.white, paddingBottom: '0.35rem' }} id="outlined-weight-helper-text">Crop price</FormHelperText>
                                    <CustomOutlinedInput
                                      disabled
                                      type="number"
                                      id="outlined-adornment-weight"
                                      value={values.PE_crop_price}
                                      endAdornment={<InputAdornment position="end">€/mt</InputAdornment>}
                                      aria-describedby="outlined-weight-helper-text"
                                      inputProps={{
                                        'aria-label': 'ecovio_crop_price',
                                        min: 0,
                                        step: 0.1,
                                      }}
                                    />
                                  </FormControl>
                                </CustomTableCell>
                              </TableRow>
                              <TableRow
                                key={'row.name33'}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <CustomTableCell style={{ borderBottom: "none" }} align="center">
                                  <FormControl sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)': { width: inputFieldSize_mobile }, marginBottom: tableMarginBottom, marginTop: tableMarginTop }} variant="outlined">
                                    <FormHelperText sx={{ color: common.white, paddingBottom: '0.35rem' }} id="outlined-weight-helper-text">Obtained yield</FormHelperText>
                                    <CustomOutlinedInput
                                      type="number"
                                      id="outlined-adornment-weight"
                                      // placeholder='' 
                                      value={values.PE_yield_obtained}
                                      onChange={handleChange('PE_yield_obtained')}
                                      endAdornment={<InputAdornment position="end">mt/ha</InputAdornment>}
                                      aria-describedby="outlined-weight-helper-text"
                                      inputProps={{
                                        'aria-label': 'PE_yield_obtained',
                                        min: 0,
                                        step: 0.1,
                                      }}
                                    />
                                  </FormControl>
                                </CustomTableCell>
                                <CustomTableCell style={{ borderBottom: "none" }} align="center">
                                  <FormControl sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)': { width: inputFieldSize_mobile }, marginBottom: tableMarginBottom, marginTop: tableMarginTop }} variant="outlined">
                                    <FormHelperText sx={{ color: common.white, paddingBottom: '0.35rem' }} id="outlined-weight-helper-text">Obtained yield</FormHelperText>
                                    <CustomOutlinedInput
                                      disabled
                                      type="number"
                                      id="outlined-adornment-weight"
                                      value={values.PE_yield_obtained}
                                      endAdornment={<InputAdornment position="end">mt/ha</InputAdornment>}
                                      aria-describedby="outlined-weight-helper-text"
                                      inputProps={{
                                        'aria-label': 'ecovio_yield_obtained_by_area',
                                        min: 0,
                                        step: 0.1,
                                      }}
                                    />
                                  </FormControl>
                                </CustomTableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </React.Fragment>
                    </React.Fragment>
                  ) : <React.Fragment>
                    <ResultsTable></ResultsTable>
                  </React.Fragment>
                }
              </div>
              <br>
              </br>
              {/*<Box sx={{ flex: '1 1 auto' }} />*/}
              {/*<Button onClick={handleNext} sx={{ mr: 1 }}>
                Next
              </Button>
              {activeStep !== steps.length &&
                (completed[activeStep] ? (
                  <Typography variant="caption" sx={{ display: 'inline-block' }}>
                    Stepppp {activeStep + 1} already completed
                </Typography>
                ) : (
                  <Button onClick={handleComplete}>
                    {completedSteps() === totalSteps() - 1
                      ? 'Finish'
                      : 'Complete Step'}
                  </Button>
                ))}*/}
            </Box>
            <span class="noPrint">
              <style>
                {`@media print {.noPrint{display: none;}}`}
              </style>
              {
                (activeStep + 1 >= 2) ? (
                  <Button sx={{ ":hover": { color: 'white', backgroundColor: '#00793A' }, fontFamily: 'HelveticaNeue', margin: '3%', borderRadius: '0', color: 'white', backgroundColor: '#65AC1E', borderColor: 'white' }} align="center" justifyContent="center" onClick={handleBack} size="large">Back</Button>
                )
                  : (
                    <Button sx={{ ":hover": { color: 'white', backgroundColor: '#00793A' }, fontFamily: 'HelveticaNeue', margin: '3%', borderRadius: '0', color: 'white', backgroundColor: '#65AC1E', borderColor: 'white' }} align="center" justifyContent="center" onClick={handleRestart} size="large">Restart</Button>
                  )
              }
              {
                (activeStep + 1 <= 2) ? (
                  <Button sx={{ ":hover": { color: 'white', backgroundColor: '#00793A' }, fontFamily: 'HelveticaNeue', margin: '3%', borderRadius: '0', color: 'white', backgroundColor: '#65AC1E', borderColor: 'white' }} align="center" justifyContent="center" onClick={handleNext} size="large">Next</Button>
                )
                  : (
                    <React.Fragment>
                    </React.Fragment>
                  )
              }
              {
                (activeStep + 1 === 3) ?
                  (
                    <Button sx={{ ":hover": { color: 'white', backgroundColor: '#00793A' }, fontFamily: 'HelveticaNeue', margin: '3%', borderRadius: '0', color: 'white', backgroundColor: '#65AC1E', borderColor: 'white' }} align="center" justifyContent="center" onClick={handleNext} size="large">Calculate</Button>
                  )
                  : (<React.Fragment></React.Fragment>)
              }
            </span>
            <span>
              {
                (activeStep + 1 >= 4) ? (
                  <React.Fragment>
                    <span>
                      <style>
                        {`@media print {.noPrint{display: none;}}`}
                      </style>
                      <span class="noPrint"><Button sx={{ ":hover": { color: 'white', backgroundColor: '#00793A' }, fontFamily: 'HelveticaNeue', margin: '3%', borderRadius: '0', color: 'white', backgroundColor: '#65AC1E', borderColor: 'white' }} align="center" justifyContent="center" onClick={handleRestart} size="large">New</Button></span>
                      <span class="noPrint"><Button sx={{ ":hover": { color: 'white', backgroundColor: '#00793A' }, fontFamily: 'HelveticaNeue', margin: '3%', borderRadius: '0', color: 'white', backgroundColor: '#65AC1E', borderColor: 'white' }} align="center" justifyContent="center" onClick={handlePrint} size="large">Print</Button></span>
                      <div class="print">
                        { /*<Divider variant="middle" />*/}
                        <br></br>

                        <Box sx={{ padding: '1rem', margin: '3%', borderRadius: '0', color: 'white', backgroundColor: '#65AC1E', borderColor: 'white', fontFamily: 'HelveticaNeue' }}><span>More information on ecovio<sup style={{lineHeight: '0px'}}>&#174;</sup> for certified soil-biodegradable mulch films:</span> <br></br><Link href="https://www.ecovio.basf.com/mulchfilm">www.ecovio.basf.com/mulchfilm</Link><br></br>Contact: biopolymers@basf.com</Box>

                      </div>
                    </span>
                    {<div>
                      <img class="print-only" src={require('../assets/print-BASFo_wh100lg_4c.png')}></img>
                    </div>}
                  </React.Fragment>
                )
                  : (<React.Fragment></React.Fragment>
                  )
              }
            </span>
          </React.Fragment>
        )}
      </div>
    </Box>
  );


  /*
    function MulchFeaturesTable() {
      return (
        <React.Fragment key="mulchfrag">
          <TableContainer key="mulchtablecontainer" align="center" style={{ background: TABLE_BG_COLOR }}>
            <Table key="mulchtable" sx={{ minWidth: 575, '@media (max-width: 625px)' : { minWidth: 260}, }} aria-label="simple table">
              <TableHead>
                <TableRow >
                  <TableCell sx={{ paddingBottom: '10px' }} style={{ borderBottom: "none", color: 'white' }} colSpan={2} align="center"><h1>Enter the features of the mulch film</h1>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <CustomTableCell style={{ borderBottom: "none" }} align="center"><h1 style={{ marginTop: '1px', marginBottom: '1px' }}>{"PE mulch film"}</h1></CustomTableCell>
                  <CustomTableCell style={{ borderBottom: "none" }} align="center"><h1 style={{ marginTop: '1px', marginBottom: '1px' }}>ecovio<sup>&#174;</sup></h1></CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody key="mulchtablebody">
                <TableRow
                  key={'row.name1'}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <CustomTableCell key="pethickcell" style={{ borderBottom: "none" }} align="center">
                    <FormControl id="2893zhsdibf" key="mulchtihckpereal" sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)' : { width: inputFieldSize_mobile}, marginBottom: tableMarginBottom, marginTop: tableMarginTop }} variant="outlined">
                      <FormHelperText key="justtomakesure" sx={{ color: common.white, paddingBottom: '0.35rem' }} id="outlined-weight-helper-text">Thickness</FormHelperText>
                      <CustomOutlinedInput
                        key="tst112"
                        type="number"
                        id="outlined-adornment-weight1"
                        value={values.pe_mulch_thickness}
                        onChange={handleChange('pe_mulch_thickness')}
                        endAdornment={<InputAdornment position="end">&#181;m</InputAdornment>}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          'aria-label': 'pe_mulch_thickness',
                          min: 0,
                          step: 0.1,
                        }}
                      />
                    </FormControl>
                  </CustomTableCell>
                  <CustomTableCell style={{ borderBottom: "none" }} align="center">
                    <FormControl id="sadsadsf" key="mulchticksecoreal" sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)' : { width: inputFieldSize_mobile}, marginBottom: tableMarginBottom, marginTop: tableMarginTop }} variant="outlined">
                      <FormHelperText sx={{ color: common.white, paddingBottom: '0.35rem' }} id="outlined-weight-helper-text">Thickness</FormHelperText>
                      <CustomOutlinedInput
                        key="test223"
                        type="number"
                        id="outlined-adornment-weight2"
                        value={values.ecovio_mulch_thickness}
                        onChange={handleChange('ecovio_mulch_thickness')}
                        endAdornment={<InputAdornment position="end">&#181;m</InputAdornment>}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          'aria-label': 'ecovio_mulch_thickness',
                          min: 0,
                          step: 0.1,
                        }}
                      />
                    </FormControl>
                  </CustomTableCell>
                </TableRow>
                <TableRow>
                  <CustomTableCell style={{ borderBottom: "none" }} align="center">
                    <FormControl sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)' : { width: inputFieldSize_mobile}, marginBottom: tableMarginBottom, marginTop: tableMarginTop }} variant="outlined">
                      <FormHelperText sx={{ color: common.white, paddingBottom: '0.35rem' }} id="outlined-weight-helper-text">Width</FormHelperText>
                      <CustomOutlinedInput
                        type="number"
                        id="outlined-adornment-weight"
                        value={values.pe_mulch_width}
                        onChange={handleChange('pe_mulch_width')}
                        endAdornment={<InputAdornment position="end">m</InputAdornment>}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          'aria-label': 'pe_mulch_width',
                          min: 0,
                          step: 0.1,
                        }}
                      />
                    </FormControl>
                  </CustomTableCell>
                  <CustomTableCell style={{ borderBottom: "none" }} align="center">
                    <FormControl sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)' : { width: inputFieldSize_mobile}, marginBottom: tableMarginBottom, marginTop: tableMarginTop }} variant="outlined">
                      <FormHelperText sx={{ color: common.white, paddingBottom: '0.35rem' }} id="outlined-weight-helper-text">Width</FormHelperText>
                      <CustomOutlinedInput
                        type="number"
                        id="outlined-adornment-weight"
                        value={values.ecovio_mulch_width}
                        onChange={handleChange('ecovio_mulch_width')}
                        endAdornment={<InputAdornment position="end">m</InputAdornment>}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          'aria-label': 'ecovio_mulch_width',
                          min: 0,
                          step: 0.1,
                        }}
                      />
                    </FormControl>
                  </CustomTableCell>
                </TableRow>
                <TableRow>
                  <CustomTableCell style={{ borderBottom: "none" }} align="center">
                    <FormControl sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)' : { width: inputFieldSize_mobile}, marginBottom: tableMarginBottom, marginTop: tableMarginTop }} variant="outlined">
                      <FormHelperText sx={{ color: common.white, paddingBottom: '0.35rem' }} id="outlined-weight-helper-text">Row distance of the crop</FormHelperText>
                      <CustomOutlinedInput
                        type="number"
                        id="outlined-adornment-weight"
                        value={values.both_row_distance}
                        onChange={handleChange('both_row_distance')}
                        endAdornment={<InputAdornment position="end">m</InputAdornment>}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          'aria-label': 'both_row_distance',
                          min: 0,
                          step: 0.1,
                        }}
                      />
                    </FormControl>
                  </CustomTableCell>
                  <CustomTableCell style={{ borderBottom: "none" }} align="center">
                    <FormControl sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)' : { width: inputFieldSize_mobile}, marginBottom: tableMarginBottom, marginTop: tableMarginTop }} variant="outlined">
                      <FormHelperText sx={{ color: common.white, paddingBottom: '0.35rem' }} id="outlined-weight-helper-text">Row distance of the crop</FormHelperText>
                      <CustomOutlinedInput
                        type="number"
                        id="outlined-adornment-weight"
                        value={values.both_row_distance}
                        onChange={handleChange('both_row_distance')}
                        endAdornment={<InputAdornment position="end">m</InputAdornment>}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          'aria-label': 'both_row_distance',
                          min: 0,
                          step: 0.1,
                        }}
                      />
                    </FormControl>
                  </CustomTableCell>
                </TableRow>
                <TableRow>
                  <CustomTableCell align="center">
                    <FormControl sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)' : { width: inputFieldSize_mobile}, marginBottom: tableMarginBottom, marginTop: tableMarginTop }} variant="outlined">
                      <FormHelperText sx={{ color: common.white, paddingBottom: '0.35rem' }} id="outlined-weight-helper-text">Purchase price of mulch film</FormHelperText>
                      <CustomOutlinedInput
                        type="number"
                        id="outlined-adornment-weight"
                        value={values.pe_mulch_purchase_price_by_weight}
                        onChange={handleChange('pe_mulch_purchase_price_by_weight')}
                        endAdornment={<InputAdornment position="end">€/kg</InputAdornment>}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          'aria-label': 'pe_mulch_purchase_price_by_weight',
                          min: 0,
                          step: 0.1,
                        }}
                      />
                    </FormControl>
                  </CustomTableCell>
                  <CustomTableCell align="center">
                    <FormControl sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)' : { width: inputFieldSize_mobile}, marginBottom: tableMarginBottom, marginTop: tableMarginTop }} variant="outlined">
                      <FormHelperText sx={{ color: common.white, paddingBottom: '0.35rem' }} id="outlined-weight-helper-text">Purchase price of mulch film</FormHelperText>
                      <CustomOutlinedInput
                        type="number"
                        id="outlined-adornment-weight"
                        value={values.ecovio_mulch_purchase_price_by_weight}
                        onChange={handleChange('ecovio_mulch_purchase_price_by_weight')}
                        endAdornment={<InputAdornment position="end">€/kg</InputAdornment>}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          'aria-label': 'ecovio_mulch_purchase_price_by_weight',
                          min: 0,
                          step: 0.1,
                        }}
                      />
                    </FormControl>
                  </CustomTableCell>
                </TableRow>
                <TableRow >
                  <CustomTableCell align="center" sx={{ borderBottom: "none" }}>
                    <FormControl sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)' : { width: inputFieldSize_mobile} }} variant="outlined">
                      <CustomResultCell value={calculatedValues.pe_mulch_total_price_by_area.toString().replace('.', ',')} heading="Total cost of mulch film" unit="€/ha"></CustomResultCell>
                    </FormControl>
                  </CustomTableCell>
                  <CustomTableCell align="center" sx={{ borderBottom: "none" }}>
                    <FormControl sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)' : { width: inputFieldSize_mobile} }} variant="outlined">
                      <CustomResultCell value={calculatedValues.ecovio_mulch_total_price_by_area.toString().replace('.', ',')} heading="Total cost of mulch film" unit="€/ha"></CustomResultCell>
                    </FormControl>
                  </CustomTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </React.Fragment>
      );
    }
  
  */























  function CollectionAndRecylingTable() {
    return (
      <React.Fragment>

        <TableContainer align="center" style={{ background: TABLE_BG_COLOR }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ paddingBottom: '10px' }} style={{ borderBottom: "none", color: 'white' }} colSpan={2} align="center"><h1>Enter costs for collection and recycling</h1>
                </TableCell>
              </TableRow>
              <TableRow>
                <CustomTableCell align="center"><h1 style={{ marginTop: '1px', marginBottom: '1px' }}>{"PE mulch film"}</h1></CustomTableCell>
                <CustomTableCell align="center"><h1 style={{ marginTop: '1px', marginBottom: '1px' }}>ecovio<sup style={{lineHeight: '0px'}}>&#174;</sup></h1></CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                key={'row.name2'}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <CustomTableCell style={{ borderBottom: "none" }} align="center">
                  <FormControl sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)': { width: inputFieldSize_mobile }, marginBottom: tableMarginBottom, marginTop: tableMarginTop }} variant="outlined">
                    <FormHelperText sx={{ color: common.white, paddingBottom: '0.35rem' }} id="outlined-weight-helper-text">Hours to collect PE film</FormHelperText>
                    <CustomOutlinedInput
                      type="number"
                      id="outlined-adornment-weight"
                      value={values.pe_collection_hours_mulch}
                      onChange={handleChange('pe_collection_hours_mulch')}
                      endAdornment={<InputAdornment position="end">h</InputAdornment>}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'pe_collection_hours_mulch',
                        min: 0,
                        step: 0.1,
                      }}
                    />
                  </FormControl>
                </CustomTableCell>
                <CustomTableCell style={{ borderBottom: "none" }} align="center">
                  <FormControl sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)': { width: inputFieldSize_mobile }, marginBottom: tableMarginBottom, marginTop: tableMarginTop }} variant="outlined">
                    <FormHelperText sx={{ color: common.white, paddingBottom: '0.35rem' }} id="outlined-weight-helper-text">Hours to collect film</FormHelperText>
                    <CustomOutlinedInput
                      disabled
                      type="number"
                      id="outlined-adornment-weight"
                      value={0}
                      endAdornment={<InputAdornment position="end">h</InputAdornment>}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'ecovio_mulch_thickness',
                        min: 0,
                        step: 0.1,
                      }}
                    />
                  </FormControl>
                </CustomTableCell>
              </TableRow>
              <TableRow>
                <CustomTableCell style={{ borderBottom: "none" }} align="center">
                  <FormControl sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)': { width: inputFieldSize_mobile }, marginBottom: tableMarginBottom, marginTop: tableMarginTop }} variant="outlined">
                    <FormHelperText sx={{ color: common.white, paddingBottom: '0.35rem' }} id="outlined-weight-helper-text">Labor cost to collect PE film</FormHelperText>
                    <CustomOutlinedInput
                      type="number"
                      id="outlined-adornment-weight"
                      value={values.pe_collection_labor_costs}
                      onChange={handleChange('pe_collection_labor_costs')}
                      endAdornment={<InputAdornment position="end">€/h</InputAdornment>}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'pe_collection_labor_costs',
                        min: 0,
                        step: 0.1,
                      }}
                    />
                  </FormControl>
                </CustomTableCell>
                <CustomTableCell style={{ borderBottom: "none" }} align="center">
                  <FormControl sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)': { width: inputFieldSize_mobile }, marginBottom: tableMarginBottom, marginTop: tableMarginTop }} variant="outlined">
                    <FormHelperText sx={{ color: common.white, paddingBottom: '0.35rem' }} id="outlined-weight-helper-text">Labor cost to collect film</FormHelperText>
                    <CustomOutlinedInput
                      disabled
                      type="number"
                      id="outlined-adornment-weight"
                      value={0}
                      endAdornment={<InputAdornment position="end">€/h</InputAdornment>}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'ecovio_mulch_width',
                        min: 0,
                        step: 0.1,
                      }}
                    />
                  </FormControl>
                </CustomTableCell>
              </TableRow>
              <TableRow>
                <CustomTableCell style={{ borderBottom: "none" }} align="center">
                  <FormControl sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)': { width: inputFieldSize_mobile }, marginBottom: tableMarginBottom, marginTop: tableMarginTop }} variant="outlined">
                    <FormHelperText sx={{ color: common.white, paddingBottom: '0.35rem' }} id="outlined-weight-helper-text">Transportation costs of collected mulch film</FormHelperText>
                    <CustomOutlinedInput
                      type="number"
                      id="outlined-adornment-weight"
                      value={values.pe_transportation_cost_mulch}
                      onChange={handleChange('pe_transportation_cost_mulch')}
                      endAdornment={<InputAdornment position="end">€/kg</InputAdornment>}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'pe_transportation_cost_mulch',
                        min: 0,
                        step: 0.1,
                      }}
                    />
                  </FormControl>
                </CustomTableCell>
                <CustomTableCell style={{ borderBottom: "none" }} align="center">
                  <FormControl sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)': { width: inputFieldSize_mobile }, marginBottom: tableMarginBottom, marginTop: tableMarginTop }} variant="outlined">
                    <FormHelperText sx={{ color: common.white, paddingBottom: '0.35rem' }} id="outlined-weight-helper-text">Transportation costs of collected mulch film</FormHelperText>
                    <CustomOutlinedInput
                      disabled
                      type="number"
                      id="outlined-adornment-weight"
                      value={0}
                      endAdornment={<InputAdornment position="end">€/kg</InputAdornment>}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'both_row_distance',
                        min: 0,
                        step: 0.1,
                      }}
                    />
                  </FormControl>
                </CustomTableCell>
              </TableRow>
              <TableRow>
                <CustomTableCell align="center">
                  <FormControl sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)': { width: inputFieldSize_mobile }, marginBottom: tableMarginBottom, marginTop: tableMarginTop }} variant="outlined">
                    <FormHelperText sx={{ color: common.white, paddingBottom: '0.35rem' }} id="outlined-weight-helper-text">Recycling fee (+ other fees)</FormHelperText>
                    <CustomOutlinedInput
                      name="search"
                      ref={searchInput}
                      type="number"
                      id="outlined-adornment-weight"
                      value={values.pe_recyling_fee}
                      onChange={handleChange('pe_recyling_fee')}
                      endAdornment={<InputAdornment position="end">€/kg</InputAdornment>}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'pe_recyling_fee',
                        min: 0,
                        step: 0.1,
                      }}
                    />
                  </FormControl>
                  <InfoBox title="This should include all fees which are due to recycle the PE mulch film. Some of them could also be paid at the purchase. "></InfoBox>
                </CustomTableCell>
                <CustomTableCell align="center">
                  <FormControl sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)': { width: inputFieldSize_mobile }, marginBottom: tableMarginBottom, marginTop: tableMarginTop }} variant="outlined">
                    <FormHelperText sx={{ color: common.white, paddingBottom: '0.35rem' }} id="outlined-weight-helper-text">Recycling fee (+ other fees)</FormHelperText>
                    <CustomOutlinedInput
                      disabled
                      type="number"
                      id="outlined-adornment-weight"
                      value={0}
                      endAdornment={<InputAdornment position="end">€/kg</InputAdornment>}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'ecovio_mulch_purchase_price_by_weight',
                        min: 0,
                        step: 0.1,
                      }}
                    />
                  </FormControl>
                </CustomTableCell>
              </TableRow>
              <TableRow>
                <CustomTableCell align="center" style={{ borderBottom: "none" }}>
                  <FormControl sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)': { width: inputFieldSize_mobile } }} variant="outlined">
                    <CustomResultCell value={calculatedValues.pe_collection_and_recyling_total_costs.toString().replace('.', ',')} heading="Total cost of collection and recycling" unit="€/ha"></CustomResultCell>
                  </FormControl>
                </CustomTableCell>
                <CustomTableCell align="center" style={{ borderBottom: "none" }}>
                  <FormControl sx={{ m: 2, width: inputFieldSize_pc, '@media (max-width: 625px)': { width: inputFieldSize_mobile } }} variant="outlined">
                    <CustomResultCell value={0.0} heading="Total cost of collection and recycling" unit="€/ha"></CustomResultCell>
                  </FormControl>
                </CustomTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </React.Fragment>
    );
  }















  /*
      function YieldAndIncomeTable() {
        return (
          <TableContainer align="center" style={{ background: TABLE_BG_COLOR }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <CustomTableCell align="center"><h1>{"PE mulch film"}</h1></CustomTableCell>
                  <CustomTableCell align="center"><h1>ecovio<sup>&#174;</sup></h1></CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  key={'row.name'}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <CustomTableCell style={{ borderBottom: "none" }} align="center">
                    <FormControl sx={{ m: 2, width: '25ch' }} variant="outlined">
                      <FormHelperText sx={{ color: common.white, paddingBottom: '0.35rem' }} id="outlined-weight-helper-text">Crop price</FormHelperText>
                      <CustomOutlinedInput
                        type="number"
                        id="outlined-adornment-weight"
                        value={values.PE_crop_price}
                        onChange={handleChange('PE_crop_price')}
                        endAdornment={<InputAdornment position="end">€/mt</InputAdornment>}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          'aria-label': 'PE_crop_price',
                        }}
                      />
                    </FormControl>
                  </CustomTableCell>
                  <CustomTableCell style={{ borderBottom: "none" }} align="center">
                    <FormControl sx={{ m: 2, width: '25ch' }} variant="outlined">
                      <FormHelperText sx={{ color: common.white, paddingBottom: '0.35rem' }} id="outlined-weight-helper-text">Crop price</FormHelperText>
                      <CustomOutlinedInput
                        disabled
                        type="number"
                        id="outlined-adornment-weight"
                        value={values.PE_crop_price}
                        endAdornment={<InputAdornment position="end">€/mt</InputAdornment>}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          'aria-label': 'ecovio_crop_price',
                        }}
                      />
                    </FormControl>
                  </CustomTableCell>
                </TableRow>
                <TableRow
                  key={'row.name'}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <CustomTableCell style={{ borderBottom: "none" }} align="center">
                    <FormControl sx={{ m: 2, width: '25ch' }} variant="outlined">
                      <FormHelperText sx={{ color: common.white, paddingBottom: '0.35rem' }} id="outlined-weight-helper-text">Obtained yield</FormHelperText>
                      <CustomOutlinedInput
                        type="number"
                        id="outlined-adornment-weight"
                        value={values.PE_yield_obtained}
                        onChange={handleChange('PE_yield_obtained')}
                        endAdornment={<InputAdornment position="end">mt/ha</InputAdornment>}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          'aria-label': 'PE_yield_obtained',
                        }}
                      />
                    </FormControl>
                  </CustomTableCell>
                  <CustomTableCell style={{ borderBottom: "none" }} align="center">
                    <FormControl sx={{ m: 2, width: '25ch' }} variant="outlined">
                      <FormHelperText sx={{ color: common.white, paddingBottom: '0.35rem' }} id="outlined-weight-helper-text">Obtained yield</FormHelperText>
                      <CustomOutlinedInput
                        disabled
                        type="number"
                        id="outlined-adornment-weight"
                        value={values.PE_yield_obtained}
                        endAdornment={<InputAdornment position="end">mt/ha</InputAdornment>}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          'aria-label': 'ecovio_yield_obtained_by_area',
                        }}
                      />
                    </FormControl>
                  </CustomTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        );
      }
  
  
  
  
  */









  function ResultsTable() {
    return (
      <React.Fragment>
        <TableContainer sx={{ paddingBottom: '25px', minWidth: 575, '@media (max-width: 625px)': { minWidth: 260 }, }} align="center" style={{ background: TABLE_BG_COLOR }}>
          <Table sx={{ maxWidth: '95%', marginBottom: '10px' }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ paddingBottom: '15px' }} style={{ borderBottom: "none", color: 'white' }} colSpan={3} align="center"><div class="print"><style>{`@media print {.print{color: black;}}`}</style><h1 style={{ marginBottom: '0.35rem' }}>Results</h1></div>
                </TableCell>
              </TableRow>
              <TableRow>
                <CustomTableCell sx={{ color: 'rgb(100,100,100)', backgroundColor: 'white', padding: '0.3rem', border: 2 }} align="right"><h1 style={{ margin: '0.1rem' }}></h1></CustomTableCell>
                <CustomTableCell sx={{ color: 'rgb(100,100,100)', backgroundColor: 'white', padding: '0.3rem', border: 2 }} align="center"><div class="print"><style>{`@media print {.print{color: black;}}`}</style><h1 style={{ margin: '0.1rem' }}>{"PE mulch film"}</h1></div></CustomTableCell>
                <CustomTableCell sx={{ color: 'rgb(100,100,100)', backgroundColor: 'white', padding: '0.3rem', border: 2 }} align="center"><div class="print"><style>{`@media print {.print{color: black;}}`}</style><h1 style={{ margin: '0.1rem' }}>ecovio<sup style={{lineHeight: '0px'}}>&#174;</sup></h1></div></CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                key={'row.name4'}
                sx={{ '&:last-child td, &:last-child th': { border: 2 } }}>
                <CustomTableCell sx={{ color: 'rgb(100,100,100)', backgroundColor: 'white', padding: '0.3rem', border: 2 }} style={{}} align="center"><div class="print"><style>{`@media print {.print{color: black;}}`}</style>Gross income in €/ha</div>
                </CustomTableCell>
                <CustomTableCell sx={{ color: 'rgb(100,100,100)', backgroundColor: 'white', padding: '0.3rem', border: 2 }} style={{}} align="center"><div class="print"><style>{`@media print {.print{color: black;}}`}</style>{calculatedValues.result_PE_gross_income.toString().replace('.', ',')}</div>
                </CustomTableCell>
                <CustomTableCell sx={{ color: 'rgb(100,100,100)', backgroundColor: 'white', padding: '0.3rem', border: 2 }} style={{}} align="center"><div class="print"><style>{`@media print {.print{color: black;}}`}</style>{calculatedValues.result_ecovio_gross_income.toString().replace('.', ',')}</div>
                </CustomTableCell>
              </TableRow>
              <TableRow
                key={'row.name5'}
                sx={{ '&:last-child td, &:last-child th': { border: 2 } }}>
                <CustomTableCell sx={{ color: 'rgb(100,100,100)', backgroundColor: 'white', padding: '0.3rem', border: 2 }} style={{}} align="center"><div class="print"><style>{`@media print {.print{color: black;}}`}</style>Total costs in €/ha</div>
                </CustomTableCell>
                <CustomTableCell sx={{ color: 'rgb(100,100,100)', backgroundColor: 'white', padding: '0.3rem', border: 2 }} style={{}} align="center"><div class="print"><style>{`@media print {.print{color: black;}}`}</style>{calculatedValues.result_PE_total_costs.toString().replace('.', ',')}</div>
                </CustomTableCell>
                <CustomTableCell sx={{ color: 'rgb(100,100,100)', backgroundColor: 'white', padding: '0.3rem', border: 2 }} style={{}} align="center"><div class="print"><style>{`@media print {.print{color: black;}}`}</style>{calculatedValues.result_ecovio_total_costs.toString().replace('.', ',')}</div>
                </CustomTableCell>
              </TableRow>
              <TableRow
                key={'row.name6'}
                sx={{ '&:last-child td, &:last-child th': { border: 2 } }}>
                <CustomTableCell sx={{ color: 'rgb(100,100,100)', backgroundColor: 'white', padding: '0.3rem', border: 2 }} style={{}} align="center"><div class="print"><style>{`@media print {.print{color: black;}}`}</style><strong>Final income in €/ha</strong></div>
                </CustomTableCell>
                <CustomTableCell sx={{ color: 'rgb(100,100,100)', backgroundColor: 'white', padding: '0.3rem', border: 2 }} style={{}} align="center"><div class="print"><style>{`@media print {.print{color: black;}}`}</style><strong>{calculatedValues.result_pe_final_income.toString().replace('.', ',')}</strong></div>
                </CustomTableCell>
                <CustomTableCell sx={{ color: 'rgb(100,100,100)', backgroundColor: 'white', padding: '0.3rem', border: 2 }} style={{}} align="center"><div class="print"><style>{`@media print {.print{color: black;}}`}</style><strong>{calculatedValues.result_ecovio_final_income.toString().replace('.', ',')}</strong></div>
                </CustomTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </React.Fragment>
    );
  }
}